'use strict';

angular.module('enervexSalesappApp').controller('ApplianceCategoryCtrl', function($scope, ApplianceCategory, VentMaterial, $stateParams, Flash, $state, _, BuildType) {
	$scope.errors = {};
	VentMaterial.query().$promise.then(function(ventMaterials){
		$scope.ventMaterials = ventMaterials;
		return BuildType.query().$promise;
	}).then(function(buildTypes){
		$scope.buildTypes = buildTypes;

		if ($stateParams.id == "new") {
			$scope.applianceCategory = {
				ruleType: 'single'
			}
		} else {
			ApplianceCategory.get({
				id: $stateParams.id
			}).$promise.then(function(applianceCategory) {
				$scope.applianceCategory = applianceCategory;
				_.each($scope.applianceCategory.names, function(name){
					var existing = _.find($scope.names, function(v){
						return v.name == name
					})
					existing.selected = true;
				})
				_.each($scope.applianceCategory.ventMaterials, function(ventMaterial){
					var existing = _.find($scope.ventMaterials, function(v){
						return v._id == ventMaterial._id
					})
					existing.selected = true;
				})
				_.each($scope.applianceCategory.buildTypes, function(buildType){
					var existing = _.find($scope.buildTypes, function(v){
						return v._id == buildType._id
					})
					existing.selected = true;
				})
			})
		}
	})
	$scope.ruleTypes = ["single","mixed"];
	$scope.names = [
		{
			name: "II"
		},
		{
			name: "IV"
		},
		{
			name: "III"
		},
		{
			name: "I - Direct Connect"
		},
		{
			name: "I - Draft Hood"
		},
		{
			name: "I - Fan Assisted"
		},
		{
			name: "Oil"
		}
	]
	$scope.ctx = {
		formChanged: false
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.applianceCategory);
			payload = _.omit(payload, "__v");
			if (payload.ruleType == 'mixed'){
				var selected = _.where($scope.names, {selected:true})
				payload.names = _.pluck(selected, "name")
			}
			payload.ventMaterials = _.filter($scope.ventMaterials, function(v){
				return v.selected
			})
			payload.buildTypes = _.filter($scope.buildTypes, function(v){
				return v.selected
			})
			if (!$scope.applianceCategory._id) {
				ApplianceCategory.save(payload).$promise.then(function(result) {
					$scope.success = true;
					$scope.applianceCategory = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created appliance category.', 0, {}, true);
					$state.go("appliance-category", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				ApplianceCategory.update({
					id: $scope.applianceCategory._id
				}, payload).$promise.then(function(applianceCategory) {
					$scope.success = true;
					$scope.applianceCategory = applianceCategory;
					Flash.create('success', '<strong>Success!</strong> Successfully updated appliance category.', 0, {}, true);
				}).catch(function(err) {
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});